.card {
  .navbar {
    background-color: #f8f8f8 !important;
    .logo {
      margin-left: 40px;
      width: 148px;
      height: 30px;
    }
  }
  .termsForm {
    margin-right: 40px;
  }
  .termsContentContainer {
    margin-left: 58px;
    margin-right: 50px;
    .termsHeading {
      margin-bottom: 12px;
      text-align: left;
      font: normal normal bold 24px/27px Open Sans;
      color: #262626;
    }
    .termsContent .innerContent {
      font: normal normal normal 16px/22px Open Sans;
    }
    font: normal normal normal 16px/22px Open Sans;
    color: #262626;
    .contentHeading {
      font: normal normal 700 16px/22px Open Sans;
      color: #262626;
    }
  }
}
