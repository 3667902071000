.card .termsForm .btn {
  margin-right: 5px;
  background-color: #243386;
  width: 100px;
  height: 40px;
  color: white;
  border: 1px solid #243386;
  font: normal normal 600 16px/22px Open Sans;
  border-radius: 5px;
}
