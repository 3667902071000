.register-page {
  padding: 0px;
  margin: 0px;
  height: 100%;
  background-color: #f0f0f0;
  max-height: 100%;
  overflow: hidden;

  .register-container {
    width: min-content;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: -120px;
    justify-content: center;
    padding: 25px 48px 17px 48px;
    align-content: center;
    .register-label {
      display: inline-block;
      font: normal normal 600 14px/19px Open Sans;
      color: #666666;
      padding-bottom: 5px;
    }
  }
  .span-content-login {
    font: normal normal normal 16px/22px Open Sans;
    color: #262626;
  }

  .popup-content .span-content {
    display: flex;
    justify-content: space-evenly;
  }

  .register {
    padding-top: 10px;
    text-align: center;
    margin-bottom: 25px;
    color: #262626;
    font: normal normal bold 30px Open Sans;
    letter-spacing: 0px;
  }
  .logos-container {
    margin: 9px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .register-logo {
      width: 79px;
    }
    .logos-separator {
      margin: 0 15px;
      width: 2px;
      background-color: #707070;
      height: 40px;
    }
  }

  .btn {
    color: #ffffff;
    font: normal normal normal 19px Open Sans;
    padding-inline: 20px;
  }

  .login-button-link {
    color: #243386;
    font-weight: 600;
  }
  .register-terms {
    display: flex;
    text-align: center;
    padding-top: 25px;
    justify-content: center;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #999999;
  }

  .terms {
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #262626;
  }

  .term {
    font: normal normal 600 14px/19px Open Sans;
    color: #243386;
    cursor: pointer;
  }

  .register-labels {
    display: flex;
    width: 100vh;
    font: normal normal medium 12px/14px SF Pro Display;
    letter-spacing: 0px;
    color: #666666;
  }
  .login-content {
    align-content: center;
  }

  .show {
    width: 24px;
    height: 24px;
    display: flex;
    float: right;
  }

  .password-container {
    margin-top: 20px;
    .input-text-password {
      height: 38px;
      margin-right: 82px;
      border-radius: 5px;
    }

    .input-text-confirm-password {
      height: 38px;
      margin-left: 59px;
      border-radius: 5px;
      width: 79%;
    }
  }
  .ant-col .col-container {
    margin-left: -36px;
  }

  .row-container {
    .col-container-left {
      padding-right: 10px;
      border-radius: 5px;
    }
    .col-container-right {
      padding-left: 10px;
      border-radius: 5px;
    }
    .input-text-lastname {
      height: 40px;
      border-radius: 5px;
    }
    .input-text-firstname {
      height: 40px;
      border-radius: 5px;
    }
  }

  .ant-row {
    flex-flow: unset !important;
    justify-content: space-between;
  }
  .register-submit {
    font: normal normal 600 16px/22px Open Sans;
    color: #ffffff !important;
    height: 40px;
    background-color: #243386 !important;
    width: 530px;
    margin: auto;
  }

  .register-btn {
    color: #ffffff;
    font: normal normal normal 19px Open Sans;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
    padding-inline: 30px;
  }

  .bar {
    width: 100vh;
    height: 100vh;
  }

  .company-name-input {
    border-radius: 5px;
    color: #666666;
  }

  .input-container {
    .company-name-input {
      border-radius: 5px;
      height: 40px;
    }
    .company-input {
      border-radius: 5px;
    }
    .react-tel-input .form-control {
      width: 100%;
      height: 40px;
    }
    .register-label {
      display: inline-block;
      font-weight: 600;
    }
    margin-top: 20px;
  }

  .input-label {
    margin-left: 55px;
  }
  .input-labels {
    margin-left: 60px;
  }
  .label-col-container {
    .register-label {
      display: inline-block;
      font-weight: 600;
    }
  }
  .col-container .register-label {
    display: inline-block;
    font-weight: 600;
    margin-left: 55px;
  }

  .ant-modal-body {
    padding: 1px;
    font-size: 20px;
    width: 470px;
  }

  .check-icon {
    margin-left: 85px;
  }
}
.main-modal {
  .popup-modal-content {
    font: normal normal bold 24px/33px Open Sans !important;
  }
  .popup-modal {
    .ant-modal-footer {
      .ant-modal-footer {
        border-top: none !important;
      }
    }
  }
  .ant-modal-centered .ant-modal {
    text-align: center !important;
  }
}
.ant-modal-centered .ant-modal {
  text-align: center !important;
  padding-bottom: 130px;
}
.ant-modal-footer {
  border-top: none !important;
}
.login-button {
  background: #243386 !important;
  color: #ffffff !important;
  width: 120px !important;
  height: 40px !important;
  margin-bottom: 30px;
}
.popup-text {
  margin-top: 10px !important;
  color: #262626;
  margin-bottom: 8px !important;
  font: normal normal bold 22px/30px Open Sans;
}
