$recBackground: url("../../assets/recommenderSystem/recBackground.svg");
$noRecommendation: url("../../assets/recommenderSystem/noRecommendation.svg");

body {
  background: #f8f8f8;
  opacity: 1;
  height: fit-content;
}

.recommender {
  font: normal normal bold 22px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  padding-top: 70px;
  display: flex;
  justify-content: space-between;
  .site-button-ghost-wrapper {
    font-size: 14px;
    font-family: "Open Sans";
  }
}
.RecommendContainer {
  background-color: white;
  height: fit-content;
  width: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 20px;
}

.describe {
  font: normal normal 600 14px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  opacity: 0.5;
  padding-top: 5px;
  padding-top: 0px;
}

.heading {
  font: normal normal 600 12px "Open Sans";
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
  display: flex;
  padding-top: 20px;
}
.ant-modal-confirm-title {
  font-weight: bolder !important;
  font-size: 20px !important;
  margin-left: 30px !important;
  margin-top: -4px;
}

.stateName:hover {
  box-shadow: 0 2px rgb(83, 81, 81);
}

.head {
  padding-left: 20px;
}

.radioRecommendation {
  margin-left: 305px;
  margin-top: -64px;
}

.NoOfRecommendtion {
  display: flex;
  padding-left: 50px;
  font: normal normal 600 12px "Open Sans";
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
  display: flex;
}

.selectOptions {
  .ant-select-selector {
    border-radius: 5px !important;
  }
  padding-left: 19px;
  margin-bottom: 75px;
}

#radiobtn {
  padding-left: 40px;
}

.recommenderSubmit {
  background: #243386 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-left: 30px;
  border-color: #243386;
  outline: none;
  font: normal normal 500 14px "Open Sans";
  letter-spacing: 0px;
  color: #ffffff;
  width: 145px;
  height: 30px;
}

.horizontalLine {
  width: 100%;
  height: 0px;
  border: 1px solid #d8d8d8;
  opacity: 1;
  margin-top: 20px;
}

.title {
  font: normal normal 600 16px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  padding-top: 20px;
  background-image: $recBackground;
  background-position: center;
  background-repeat: no-repeat;
  padding-left: 20px;
  height: 330px;
  position: relative;
  opacity: 1;
}

.ant-card-bordered {
  min-height: 470px;
}

.titles {
  font: normal normal 600 16px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 30px;
}

.imageTitle {
  position: relative;
  background-image: $noRecommendation;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  font: normal normal bold 16px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  height: 230px;
  justify-content: center;
  align-items: flex-end;
  opacity: 1;
}
.spacing {
  padding: 10px;
}
.message {
  display: flex;
  font: normal normal 500 12px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  justify-content: space-around;
  opacity: 0.5;
}

.styles {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
}
.originState {
  display: flex;
  align-items: center;
  font-weight: bolder;
}
.TotalTrasaction {
  display: flex;
  align-items: center;
  font-weight: bolder;
}
.exportCountries {
  display: flex;
  align-items: center;
  font-weight: bolder;
}
.ant-card-bordered:hover {
  box-shadow: 0 0 10px rgb(83, 81, 81);
}

.ant-card {
  border-radius: 40px !important;
}
.cards {
  display: flex;
}
.InfoModal .ant-modal-content {
  border-radius: 40px;
}

.ant-card-head-title {
  height: 110px;
  font: normal normal 600 14px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
}

.countName {
  background: #f2f2f280;
  display: flex;
  margin-top: 60px;
  font-size: 14px;
  width: 100%;
  align-items: center;
  margin-bottom: 50px;
  justify-content: space-between;
}
.countName1 {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 0.5;
  display: flex;
  margin-top: 60px;
  font-size: 16px;
  width: 100%;
  align-items: center;
  margin-bottom: 50px;
  margin-left: 10px;
  justify-content: center;
}
.count {
  font: normal normal 600 14px "Open Sans";
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
  display: flex;
}
.totalCountries {
  font: normal normal normal 11px "Open Sans";
  letter-spacing: 0px;
  color: #666666;
  align-items: center;
}

.line {
  border: 1px solid;
  width: 0px;
  margin-top: 16px;
  height: 50px;
  margin-bottom: 16px;
  margin-left: 10px;
  margin-right: 10px;
}
.line1 {
  border: 1px solid;
  width: 0px;
  margin-top: 16px;
  height: 50px;
  margin-bottom: 16px;
  margin-left: 10px;
  margin-right: 10px;
}

.ant-card-head-title {
  padding-top: 20px;
}

.TotalTransaction {
  margin-left: 5px;
}

.ExportCountries {
  margin-left: 5px;
}

.ant-card-meta-title {
  white-space: unset !important;
  text-overflow: unset !important;
}
.CountryInfo {
  font-weight: bolder;
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-size: 16px;
  margin-bottom: 0px;
}

.meta {
  height: 50px;
}

.viewAll {
  display: flex;
  float: right;
  padding-top: 10px;
  font: normal normal 600 14px "Open Sans";
  letter-spacing: 0px;
  color: #243386;
  opacity: 1;
  cursor: pointer;
}

.statevalue {
  display: flex;
  float: right;
  text-align: left;
  font: normal normal 14px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
}

.stateName {
  text-align: left;
  font: normal normal 14px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  cursor: pointer;
}

.pdfDownload {
  display: flex;
  float: right;
  padding-right: 20px;
  cursor: pointer;
}
.ant-btn-primary {
  background-color: #243386 !important;
}

.rupeebtn {
  font-family: "Open Sans" !important;
  background-color: white !important;
  color: #262626 !important;
  width: 100px !important;
  height: 40px !important;
  margin-right: -2px;
  border-radius: 5px 0 0 5px !important;
}

.rupee,
.rupee:focus {
  background-color: #243386 !important;
  color: white !important;
  width: 100px !important;
  height: 40px !important;
  padding: 0px !important;
  border-radius: 5px 0 0 5px !important;
}

.dollerbtn {
  font-family: "Open Sans" !important;
  background-color: white !important;
  color: #262626 !important;
  width: 100px !important;
  height: 40px !important;
  border-radius: 0 5px 5px 0 !important;
}

.doller,
.doller:focus {
  background-color: #243386 !important;
  color: white !important;
  width: 100px !important;
  height: 40px !important;
  padding: 0px !important;
  border-radius: 0 5px 5px 0 !important;
}

.print {
  display: block;
}
@media print {
  .pdfDownload {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .heading {
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .NoOfRecommendtion {
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .radioRecommendation {
    width: 100%;
    margin-left: 0px;
    margin-top: 30px;
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .recommender {
    padding-left: 0px;
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .App {
    display: block;
  }
}
