@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500&display=swap");
@import "bootstrap/dist/css/bootstrap.min.css";
@import "~antd/dist/antd.css";
body {
  margin: 0;
  font-family: "open sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
}
