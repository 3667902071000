body {
  background: #f8f8f8;
  opacity: 1;
  height: 100vh;
  .ant-modal-title {
    font-weight: 600;
    font-size: 20px;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    border-radius: 10px;
  }
}
.modalClass > .ant-modal-content > .ant-modal-body {
  height: 550px;
  overflow-y: auto;
}

.nav {
  box-shadow: 0px 3px 6px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;

  #dashboard {
    &.container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .logo-Acc-Wrapper {
      height: 45px;
      width: 120px;
      padding: 3px;
      border-radius: 5px;
      box-shadow: 0px 3px 6px #00000029;
    }
    .AccountIcon {
      color: #ffffff;
      background-color: #247986;
    }
  }

  .page {
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #666666;
  }
}

.exportDashboard {
  display: flex;
  justify-content: space-between;
}

.exportHeader {
  font: normal normal bold 22px "Open Sans";
  color: #262626;
  margin-bottom: 0px;
}
.yearSelect .ant-select-selector {
  border-radius: 5px !important;
  opacity: 1;
}
#currency-btn {
  display: flex;
  justify-content: flex-end;
  &.site-button-ghost-wrapper {
    border-radius: 5px;
    color: black;
    height: 40px;
    margin-right: 0px !important;
    justify-content: center;
    margin-right: unset;
  }
  .rupee-btn {
    background-color: white;
    // border-radius: 5px;
    // transition: width 10s;
    color: #262626;
    height: 40px;
    border: 1px solid #d8d8d8;
    font-size: 14px;
    font-family: "open Sans";
  }
  .onclick-rupee-btn {
    background-color: #243386;
    color: white;
    margin-right: -2px;
    height: 40px;
    border-radius: 5px 0px 0px 5px;
  }
  .dollar-btn {
    background-color: white;
    color: #262626;
    height: 40px;
    border-radius: 0 5px 5px 0;
    border: 1px solid #d8d8d8;
    font-size: 14px;
    font-family: "open Sans";
  }
  .onclick-dollar-btn {
    background-color: #243386;
    color: white;
    height: 40px;
    border: 1px solid #d8d8d8;
    font-size: 14px;
    font-family: "open Sans";
  }
}
.ant-select-selector {
  height: 38px;
  border-radius: 5px;
}

.total-exportvalue-percentage {
  display: flex;
  align-items: center;
}
.total-exportvalue {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: 120px;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-right: 20px;
}
.exportvalue-header {
  display: flex;
  justify-content: space-between;
}
.total-yot-header {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: 120px;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.textExports {
  font: normal normal 600 14px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
}
.value-header {
  font: normal normal 600 12px/17px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  opacity: 0.5;
}
.amountLabel {
  font: normal normal bold 25px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
}
.totalPercent {
  font: normal normal bold 25px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
}

.total-export-growth-charts {
  margin-top: 20px;
  .total-export-growth-header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .chart-btn {
      .site-button-ghost-wrapper {
        margin-right: unset;
      }
    }
  }
}

.pie-chart-state-country {
  height: 550px;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 20px !important;
  box-shadow: 0px 3px 6px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-right: 10px;
}
.piechart-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}
.state-country-btn {
  min-height: 65px !important;
  margin-right: 20px;
}

#state-country {
  display: flex;
  align-items: center;
  &.site-button-ghost-wrapper {
    border-radius: 5px;
    color: black;
    height: 40px;
  }

  .country {
    background-color: white;
    color: #262626;
    height: 40px;
    border-radius: 5px 0 0 5px;
    border: 1px solid #d8d8d8;
    margin-top: 0px;
  }
  .onclick-country-btn {
    background-color: #243386;
    color: white;
    height: 40px;
    margin-right: -2px;
    border-radius: 5px 0 0 5px;
    border: 1px solid #d8d8d8;
    margin-top: 0px;
  }
  .states {
    background-color: white;
    border-radius: 0 5px 5px 0;
    color: #262626;
    height: 40px;
    border: 1px solid #d8d8d8;
    font-size: 14px;
    font-family: "open Sans";
    padding-left: 20px;
    padding-right: 20px;
  }
  .onclick-states-btn {
    background-color: #243386;
    color: white;
    height: 40px;
    border-radius: 0 5px 5px 0;
    border: 1px solid #d8d8d8;
    margin-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .state-country-btnheader {
    margin-left: 20px;
    margin-bottom: 0px;
  }
}
.state-country-btn {
  display: flex;
  min-height: 65px;
  margin-top: 15px;
}
.pie-chart-wrapper {
  margin-top: 70px;
  height: 340px !important;
}
.piechart-wrapper {
  width: 100%;
  height: 100%;
}
.pie-chart-1 {
  width: 100%;
  height: 350px;
}
.pie-chart-commodity {
  height: 550px;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-left: 10px;
}
.chartheader-commodity {
  display: flex;
  justify-content: space-between;
}
.pie-chart-2 {
  width: 100%;
  height: 250px !important;
}
.chartHeader {
  font: normal normal bold 14px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
}
.state-country-btnheader {
  font: normal normal 600 13px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  opacity: 0.5;
}
.commodityPercentage-header {
  font: normal normal bold 14px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
}
.commodityYear {
  font: normal normal 600 12px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  opacity: 0.5;
}
.chart-btn {
  #barchart-piechart-btn {
    &.site-button-ghost-wrapper {
      border-radius: 5px;
      color: black;
      height: 40px;
    }
    .piechart {
      background-color: white;
      border-radius: 0 5px 5px 0;
      color: #262626;
      height: 40px;
      border: 1px solid #d8d8d8;
    }
    .onclick-piechart-btn {
      background-color: #243386;
      color: white;
      height: 40px;
      border-radius: 0 5px 5px 0;
    }
    .barchart {
      background-color: white;
      color: #262626;
      height: 40px;
      border-radius: 5px 0 0 5px;
      border: 1px solid #d8d8d8;
      margin-top: 0px;
    }
    .onclick-barchart-btn {
      background-color: #243386;
      color: white;
      height: 40px;
      border-radius: 5px 0 0 5px;
      margin-right: -2px;
      border: 1px solid #d8d8d8;
      margin-top: 0px;
    }
  }
}

.barchart {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 5px;
  height: 100% !important;
  box-shadow: 0px 3px 6px #00000029;
  margin-top: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-bottom: 20px;
}
.barchart-header {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bargraphRadio-btn {
  margin-right: 20px;
  font: normal normal 600 14px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
}
.select-btn {
  display: flex;
  margin-right: 20px;
  font-family: "Open Sans";
  font-size: 14px;
}
.country-state-select {
  .ant-select-selector {
    border-radius: 5px !important;
  }
  border-radius: 5px;
  margin-right: 10px;
  font: normal normal 600 14px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
}
.commodity-select {
  .ant-select-selector {
    border-radius: 5px !important;
  }
  border-radius: 5px;
  font: normal normal 600 14px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
}
.barchart-for {
  color: #999999;
}
.barchart-pdf {
  margin-left: 5px;
  margin-bottom: 8px;
  width: 25px;
}
.total-export {
  transform: matrix(0, -1, 1, 0, 0, 0);
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  font-family: "open sans";
  color: #262626;
  font-weight: bold;
  min-width: 130px;
  margin-bottom: 100px;
}
.barchartWrap {
  display: flex;
  align-items: center;
}
.barchartData {
  width: 100%;
}
.barchartSummary {
  margin-left: 20px;
  margin-top: 20px;
  font: normal normal 600 14px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  text-transform: capitalize;
  display: flex;
  border: 1px solid #d8d8d8;
  padding: 20px;
  border-radius: 5px;
}
.ant-select-selector {
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
}
.commoditiesgraph {
  font: normal normal bold 16px "Open Sans";
  letter-spacing: 0px;
  color: #262626;
  margin-left: 10px;
}
.barchart-country-state-header {
  font: normal normal 600 13px "Open Sans";
  letter-spacing: 0px;
  opacity: 1;
  margin-left: 10px;
  margin-top: 20px;
}
.bargraph-display {
  margin-top: 20px;
}
.summary {
  color: #999999;
}
.exportedData {
  .modalClass {
    .ant-modal-body {
      // height: 500px !important;
    }
  }
  font: normal normal 600 13px "Open Sans";
}

.ant-pagination-options {
  visibility: hidden;
}
#TableList {
  background-color: #243386;
  color: white;
  height: 35px;
  border-radius: 5px;
  float: right;
  border-color: #243386;
}

@media screen and (max-width: 600px) {
  .barchartsummary {
    width: 80%;
    display: block;
    margin-right: 50px;
  }
}
.g2-tooltip {
  color: white !important;
  background-color: black !important;
  height: fit-content !important;
  width: 150px !important;
  align-items: center;
  margin-inline: 70px;
  margin-bottom: 10px !important;
  padding: 10px !important;
}
@media screen and (max-width: 600px) {
  .year-select {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .App {
    width: 145vh;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .exportdashboard {
    display: block;
  }
}
@media screen and (max-width: 1115px) {
  .pie-chart-commodity {
    display: block;
    margin-left: 0px;
    margin-top: 20px;
    width: 100%;
  }
}
@media screen and (max-width: 1115px) {
  .pie-chart-state-country {
    display: block;
    margin-left: 0px;
    margin-top: 20px;
    width: 100%;
  }
}
@media print {
  .state-country-pdf {
    display: none;
  }
}

@media print {
  .barchart-pdf {
    display: none;
  }
}
.containerLoader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  #currency-btn {
    margin-top: 20px;
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  #currency-btn {
    margin-top: 20px;
    width: 100%;
    margin-right: 50px;
  }
}
