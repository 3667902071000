.App {
  padding: 0px;
  margin: 0px;
  background-color: #f0f0f0;
  height: 100vh;
  align-self: center;
}

.login {
  height: 33px;
  padding-bottom: 40pxpx;
  margin-left: 200px;
  color: #262626;
  font: normal normal bold 30px Open Sans;
  letter-spacing: 0px;
}
.logos-container {
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .diatoz-logo {
    width: 79px;
    margin-top: 35px;
  }
  .logo {
    margin-top: 35px;
  }
  .logos-separator {
    margin-top: 35px !important;
    width: 2px;
    background-color: #707070;
    height: 40px;
  }
}

.btn {
  color: #ffffff;
  font: normal normal normal 19px Open Sans;
  padding-inline: 20px;
}

.login-container {
  ant-btn[enabled] {
    cursor: pointer !important;
  }
  width: 573px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: -200px;
  padding: 25px 48px 17px 48px;
  .login {
    margin-bottom: 40px;
  }
}

.login-terms {
  display: flex;
  text-align: center;
  padding-top: 25px;
  justify-content: center;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #999999;
}

.register-button-link {
  color: #243386;
  font-weight: 600;
  margin-left: 5px;
}
.terms {
  display: flex;
  text-align: center;
  margin-top: 16px;
  justify-content: center;
  font: normal normal normal 14px/19px Open Sans;
  color: #262626;
}

.term {
  font: normal normal 600 14px/19px Open Sans;
  color: #243386;
  cursor: pointer;
}

.Labels {
  display: flex;
  width: 100vh;
  margin-top: 25px;
  font-weight: 600;
  margin-bottom: 5px;
  letter-spacing: 0px;
  color: #666666;
}

.show {
  width: 24px;
  height: 24px;
  display: flex;
  float: right;
}

.password-input {
  margin-bottom: 5px;
  border-radius: 5px !important;
  height: 40px;
}

.submit {
  font: normal normal 600 16px/22px Open Sans;
  color: #ffffff !important;
  height: 40px !important;
  background-color: #243386 !important;
  width: 100%;
  color: #ffffff;
  margin: auto;
}

.login-btn {
  color: #ffffff;
  font: normal normal normal 19px Open Sans;
  margin-top: 5px;
  padding-inline: 30px;
}

.bar {
  width: 100vh;
  height: 100vh;
}
